import React, { useState } from 'react';
import { ethers } from 'ethers'
import rule2 from '../image/rule2.jpg'
import rule1 from '../image/rule1.jpg'

const ModalRule = ({ open, onClose }) => {
    if (!open) return null;

    return (
        <div onClick={onClose} className='overlay'>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="modalRuleContainer"
            >
                <img src={rule2} alt="rule" />
                <img src={rule1} alt="rule" onClick={onClose}/>
                <div className='modalRight'>
                    <p className='closeBtn' onClick={onClose}>
                        X
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ModalRule;