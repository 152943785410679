import React, { useState } from 'react';
import { ethers } from 'ethers'
import LoadingAnimation from '../loading/Loading';
let guessing = false
const ModalLS = ({ open, onClose, TokenContract, defaultAccount, LotteryAddress, LotteryCA, playVideo, isWin }) => {
  
  const [showAnimation, setShowAnimation] = useState(false);

  const makeApprove = async () => {
    const approveAmount = ethers.BigNumber.from("10000000000000000000000000");
    let result = await TokenContract.approve(LotteryAddress, approveAmount);
  }

  const checkAllowance = async () => {
    let allowance = await TokenContract.allowance(defaultAccount, LotteryAddress);
    const approveAmount = ethers.BigNumber.from("1000000000000000000000000");
    if (allowance > approveAmount)
      return true;
    return false;
  }

  const checkResult = async (number, betTime) => {
    console.log("Checking Result...");
    try {
      let result = await LotteryCA.viewHistory2(defaultAccount);
      if (result[betTime] === undefined) {
        setTimeout(
          async function () {
            checkResult(number, betTime)
          }, 1000)
        return;
      }
      let winOrLose = result[betTime][5];
      console.log("The bet result is : " + winOrLose);
      isWin(winOrLose)

      if (number === 1) {
        console.log("Your bet is Large!")
        if (winOrLose > 0) {
          /*Todo : Pop a large result wheel : 5-9 */
          playVideo(5)
        }
        else {
          /*Todo : Pop a small result wheel : 0-4 */
          playVideo(0)
        }

      }
      if (number === 0) {
        console.log("Your bet is Small!")
        if (winOrLose < 1) {
          /*Todo : Pop a large result wheel : 5-9 */
          playVideo(5)
        }
        else {
          /*Todo : Pop a small result wheel : 0-4 */
          playVideo(0)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const makeGuessLarge = async (event) => {
    event.preventDefault();

    const betTime = await LotteryCA.userInfo(defaultAccount);
    const value = document.getElementById("amount").value;

    if (value.includes(".") || value === "") {
      alert("Invalid number!! Integer Amount.")
      return;
    }

    let haveApproved = await checkAllowance()
    if (!haveApproved) await makeApprove()

    guessing = true;

    let result = await LotteryCA.makeGuess(value, 1, 1);
    if (result) {
      setShowAnimation(true);
      console.log("Guessing Large")
      setTimeout(function () {
        guessing = false
      }, 10000)
    }

    setTimeout(
      async function () {
        await checkResult(1, betTime)
      }, 1000)
  }

  const makeGuessSmall = async (event) => {
    event.preventDefault();

    const betTime = await LotteryCA.userInfo(defaultAccount);
    const value = document.getElementById("amount").value;

    if (value.includes(".") || value === "") {
      alert("Invalid number!! Integer Amount.")
      return;
    }

    let haveApproved = await checkAllowance()
    if (!haveApproved) await makeApprove()
    guessing = true;

    let result = await LotteryCA.makeGuess(value, 1, 0);
    if (result) {
      setShowAnimation(true);
      console.log("Guessing Small")
      setTimeout(function () {
        guessing = false
      }, 10000)
    }

    setTimeout(
      async function () {
        await checkResult(0, betTime)
      }, 1000)
  }
  
  const handleAnimation = () => {
    if (!guessing)
      if (showAnimation) {
        setShowAnimation(false);
        console.log("Set false")
      }
  }

  handleAnimation()

  if (!open) return null;
  return (
    <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer" id="foxtls"
      >
        <div className='modalRight'>
          <p className='closeBtn' onClick={onClose}>
            X
          </p>
          <div className='content'>
            <form>
              <h4 id="transparentWord">FOXT</h4><br />

              <input autoComplete="off" type="number" id="amount" name="amount" placeholder="≧ 30"/><br />
            </form>
            {showAnimation && <LoadingAnimation />}
          </div>
          <div className='btnContainer'>
            <input type="submit" value="Large" className='btnPrimary' onClick={makeGuessLarge} id="btnTransparent"/>
            <input type="submit" value="Small" className='btnOutline' onClick={makeGuessSmall} id="btnTransparent"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLS;