import React from 'react';
import { ScaleLoader } from 'react-spinners';
import './Loading'

class LoadingAnimation extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 5000); // simulates an async action, and hides the spinner
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <div className='loading-overlay'>
                    <div className='loading'>
                        <ScaleLoader
                            sizeUnit={"px"}
                            size={150}
                            color={'#123abc'}
                            loading={loading}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default LoadingAnimation;
