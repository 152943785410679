import React, { useState } from 'react';
import { ethers } from 'ethers'
import LoadingAnimation from '../loading/Loading';
let guessing = false;
const ModalSD = ({ open, onClose, TokenContract, defaultAccount, LotteryAddress, LotteryCA, playVideo, isWin }) => {

  const [showAnimation, setShowAnimation] = useState(false);

  const checkResult = async (number, betTime) => {
    console.log("Checking Result...");
    try {
      let result = await LotteryCA.viewHistory2(defaultAccount);
      if (result[betTime] === undefined) {
        setTimeout(
          async function () {
            checkResult(number, betTime)
          }, 1000)
        return;
      }
      let winOrLose = result[betTime][5];
      console.log("The bet result is : " + winOrLose);
      isWin(winOrLose)

      if (number === 1) {
        console.log("Your bet is Single!")
        if (winOrLose > 0) {
          /*Todo : Pop a single result wheel : 1 3 5 7 9 */
          playVideo(1)
        }
        else {
          /*Todo : Pop a small result wheel : 0 2 4 6 8 */
          playVideo(2)
        }

      }
      if (number === 0) {
        console.log("Your bet is Double!")
        if (winOrLose < 1) {
          /*Todo : Pop a single result wheel : 1 3 5 7 9 */
          playVideo(1)
        }
        else {
          /*Todo : Pop a small result wheel : 0 2 4 6 8 */
          playVideo(2)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const makeApprove = async () => {
    const approveAmount = ethers.BigNumber.from("10000000000000000000000000");
    let result = await TokenContract.approve(LotteryAddress, approveAmount);
    if (!result) return;
  }

  const checkAllowance = async () => {
    let allowance = await TokenContract.allowance(defaultAccount, LotteryAddress);
    const approveAmount = ethers.BigNumber.from("1000000000000000000000000");
    if (allowance > approveAmount)
      return true;
    return false;
  }

  const makeGuessSingle = async (event) => {
    event.preventDefault();

    const betTime = await LotteryCA.userInfo(defaultAccount);
    const value = document.getElementById("amount").value;

    if (value.includes(".") || value === "") {
      alert("Invalid number!! Integer Amount.")
      return;
    }

    let haveApproved = await checkAllowance()
    if (!haveApproved) await makeApprove()
    guessing = true;
    let result = await LotteryCA.makeGuess(value, 0, 1);
    if (result) {
      setShowAnimation(true);
      console.log("Guessing Single")
      setTimeout(function () {
        guessing = false
      }, 10000)
    }

    setTimeout(
      async function () {
        await checkResult(1, betTime)
      }, 1000)
  }

  const makeGuessDouble = async (event) => {
    event.preventDefault();

    const betTime = await LotteryCA.userInfo(defaultAccount);
    const value = document.getElementById("amount").value;

    if (value.includes(".") || value === "") {
      alert("Invalid number!! Integer Amount.")
      return;
    }

    let haveApproved = await checkAllowance()
    if (!haveApproved) await makeApprove()
    guessing = true;
    let result = await LotteryCA.makeGuess(value, 0, 0);

    if (result) {
      setShowAnimation(true);
      console.log("Guessing Double")
      setTimeout(function () {
        guessing = false
      }, 10000)
    }


    setTimeout(
      async function () {
        await checkResult(0, betTime)
      }, 1000)
  }

  const handleAnimation = () => {
    if (!guessing)
      if (showAnimation) {
        setShowAnimation(false);
        console.log("Set false")
      }
  }

  handleAnimation()

  if (!open) return null;
  return (
    <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer" id="foxtsd"
      >

        <div className='modalRight'>
          <p className='closeBtn' onClick={onClose}>
            X
          </p>
          <div className='content'>
            <form>
              <h4 id="transparentWord">FOXT</h4><br />
              <input autoComplete="off" type="text" id="amount" name="amount" placeholder="≧ 30" /><br />
            </form>
            {showAnimation && <LoadingAnimation />}
          </div>
          <div className='btnContainer'>
            <input type="submit" value="Single" className='btnPrimary' onClick={makeGuessDouble} id="btnTransparent"/>
            <input type="submit" value="Double" className='btnOutline' onClick={makeGuessSingle} id="btnTransparent"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSD;