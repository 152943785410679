import React from 'react'
import HistoryCard from './HistoryCard'

const divStyle = {
    overflow :"hidden"
}

const HistoryContainer = ({ data , decimal}) => {
  return (
    <div className="historyContainer" style = {divStyle}>
      {data.map((data, index) => {
        return <HistoryCard data={data} key={index} decimal={decimal}/>
      })}
    </div>
  )
}

export default HistoryContainer