import React, { useState } from 'react'
import Topplace2 from '../top3/Topplace2'
import HistoryContainer from '../history/HistoryContainer'
import PoolImage from '../image/Pool.png';


// const TopthreeAndHistory = (Name,LotteryCA, openRule, decimal, data) => {
const TopthreeAndHistory = (props) => {
    console.log(props)
    console.log(props.decimal)

    const claimTop3 = async () => {
        try {
            let timestamp = new Date().getTime();
            let time = Math.floor(timestamp / 1000);
            let day = Math.floor(time / 86400);
            console.log(day)
            let result = await props.LotteryCA.claimFromRewardPool(day - 1);
            console.log(result)
        } catch (err) { console.log(err) }
    }

    const [poolValue, setPoolValue] = useState('0')
    const [caValue, setCAValue] = useState('0')

    const getPoolValue = async () => {
        let result = await props.LotteryCA.viewRewardPoolBalance()
        let cabalance = await props.LotteryCA.viewTokenBalance()
        setPoolValue((result / Math.pow(10, props.decimal)).toFixed(0));
        setCAValue((cabalance / Math.pow(10, props.decimal)).toFixed(0));
    }

    getPoolValue();

    return (
        <div >
            <div className="FirstThreeTable">
                <br />
                <Topplace2
                    object={props}
                    day={0}
                />
            </div>
            <div className="FirstThreeTable">
                <div align="right">
                    <button onClick={claimTop3} id="ClaimButton">Claim </button>
                </div>
                <br />
                <Topplace2
                    object={props}
                    day={1}
                />
            </div>

            <div>
                <div className="Pool">
                    <p className='words'> {poolValue}</p>
                    <img src={PoolImage} id="PoolImage" alt="PoolImage" />
                    <p className='words2'> {caValue}</p>
                </div>
            </div>
            <div className="StatusTable">
                <br/>
                <div className="TableTop">{props.Name} History | Total Bets : {props.bet}</div>
                <HistoryContainer
                    data={props.data}
                    decimal={props.decimal}
                />
            </div>
        </div>
    )
}

export default TopthreeAndHistory
